import Wallpaper from "../../components/Wallpaper/Wallpaper";
import style from "./Menu.module.scss";
import Empty from "../../components/Empty/Empty";
import Carte from "./components/Carte";
import Questions from "../../assets/images/Icons/Questions.png";
import { useEffect, useState } from "react";
import Fermer from "../../assets/images/Icons/Fermer.png";
import firebase from 'firebase/compat/app';

const Menu = () => {
  const [questions, setQuestions] = useState(false);
  const [current, setCurrent] = useState("");
  const [questionContenu, setQuestionContenu] = useState({
    enonce1: "",
    enonce2: "",
    enonce3: "",
    reponses1: [],
    reponses2: [],
    reponses3: [],
    reponseQ1: "",
    reponseQ2: "",
    reponseQ3: ""
  });
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = firebase.firestore();
        const menuRef = db.collection('Menu');
        const doc = await menuRef.get();
  
        if (doc.exists) {
          const data = doc.data();
          // Convertissez les données Firestore en tableau
          const menuArray = Object.values(data);
  
          // Ajoutez l'ID du document à chaque élément du tableau
          const menuItemsWithId = menuArray.map((plat, index) => {
            return {
              id: Object.keys(data)[index], // L'ID est extrait ici
              ...plat,
            };
          });
  
          // Triez les plats par un critère, par exemple, par leur nom (id)
          menuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
  
          setMenuItems(menuItemsWithId);

          
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
  
    fetchData();
  }, []);
 

  const handleQuestions = () => {
    setQuestions(!questions);
    document.body.classList.add("no-scroll");
  };

  const handleCloseQuestions = () => {
    setQuestions(false);
    setCurrent("")
    document.body.classList.remove("no-scroll");
  };

  const handleEntréeSubmit = (e) => {
  e.preventDefault();
  setCurrent("Entrée Question : 2/3");
};

const handleEntrée2Submit = (e) => {
  e.preventDefault();
  setCurrent("Entrée Question : 3/3");
};

const handleEntrée3Submit = (e) => {
  e.preventDefault();
};

  return (
    <div className={style.container}>
      <Wallpaper picture="Menu" text="Cartes et Menu" />
      <div className={style.body}>
        {questions && (
          <div className={style.containerQuestions}>
            <img src={Fermer} alt="Fermer le questionnaire" onClick={handleCloseQuestions} />
            <h2>Questionnaire {current}</h2>
            {current === "" && <p>Vous hésitez entre plusieurs choix ? Laissez-vous guider par un petit questionnaire rapide ! Que voulez-vous chercher ?</p> }
            {!current && (
              <div className={style.btnQuestions}>
                <div onClick={() => {setCurrent("Entrée"); setQuestionContenu({ enonce1: "Préférez-vous quelque chose de léger ou de plus consistant pour commencer votre repas ?", enonce2: "Avez-vous une préférence pour un type spécifique d'ingrédient principal dans votre entrée ?", enonce3: "Souhaitez-vous une entrée chaude ou froide ?", reponses1: ["Léger", "Consistant"], reponses2: ["Légumes", "Poisson", "Viande"], reponses3: ["Chaude", "Froide"], reponseQ1: "", reponseQ2: "", reponseQ3: "" })}}>
                  <p>Entrée</p>
                </div>
                <div onClick={() => {setCurrent("Plat"); setQuestionContenu({ enonce1: "", enonce2: "", enonce3: "", reponses1: [], reponses2: [], reponses3: [], reponseQ1: "", reponseQ2: "", reponseQ3: "" })}}>
                  <p>Plat</p>
                </div>
                <div onClick={() => {setCurrent("Dessert"); setQuestionContenu({ enonce1: "", enonce2: "", enonce3: "", reponses1: [], reponses2: [], reponses3: [], reponseQ1: "", reponseQ2: "", reponseQ3: "" })}}>
                  <p>Dessert</p>
                </div>
                <div onClick={() => {setCurrent("Cocktail"); setQuestionContenu({ enonce1: "", enonce2: "", enonce3: "", reponses1: [], reponses2: [], reponses3: [], reponseQ1: "", reponseQ2: "", reponseQ3: "" })}}>
                  <p>Cocktail</p>
                </div>
              </div>
            )}
           

{current === "Entrée 1/3" && (
  <form onSubmit={handleEntréeSubmit}>
    <h3>Question 1</h3>
    <p>{questionContenu.enonce1}</p>
    <select value={questionContenu.reponseQ1} onChange={(e) => setQuestionContenu({ ...questionContenu, reponseQ1: e.target.value })}>
      <option value="">Sélectionnez une option</option>
      {questionContenu.reponses1.map((reponse, index) => (
        <option key={index} value={reponse}>
          {reponse}
        </option>
      ))}
    </select>
    
    <button type="submit">Suivant</button>
  </form>
)}

{current === "Entrée 2/3" && (
  <form onSubmit={handleEntrée2Submit}>
    <h3>Question 2</h3>
    <p>{questionContenu.enonce2}</p>
    <select value={questionContenu.reponseQ2} onChange={(e) => setQuestionContenu({ ...questionContenu, reponseQ2: e.target.value })}>
      <option value="">Sélectionnez une option</option>
      {questionContenu.reponses2.map((reponse, index) => (
        <option key={index} value={reponse}>
          {reponse}
        </option>
      ))}
    </select>
    
    <button type="submit">Suivant</button>
  </form>
)}

{current === "Entrée 3/3" && (
  <form onSubmit={handleEntrée3Submit}>
    <h3>Question 3</h3>
    <p>{questionContenu.enonce3}</p>
    <select value={questionContenu.reponseQ3} onChange={(e) => setQuestionContenu({ ...questionContenu, reponseQ3: e.target.value })}>
      <option value="">Sélectionnez une option</option>
      {questionContenu.reponses3.map((reponse, index) => (
        <option key={index} value={reponse}>
          {reponse}
        </option>
      ))}
    </select>
    
    <button type="submit">Valider</button>
  </form>
)}

            {current === "Plat" && (
              <div>
                
              </div>
            )}
            {current === "Dessert" && (
              <div>
               
              </div>
            )}
          </div>
        )}
        <div className={style.title}>
          <h2>Menu du Jardin Gourmand</h2>
          <Empty />
        </div>
        <Carte categorie="Cocktail de la semaine" />
        <Carte categorie="Plat de la semaine" />
        <Carte categorie="Cocktails" alcool={true}/>
        <Carte categorie="Cocktails" alcool={false}/>
        <Carte categorie="Entrées" />
        <Carte categorie="Plats" />
        <Carte categorie="Desserts" />

        <img
          className={style.questions}
          src={Questions}
          alt="Pas d'idée de plat ?"
          onClick={handleQuestions}
        />
      </div>
    </div>
  );
};

export default Menu;
