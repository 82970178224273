import Button from "../Button/Button"
import Empty from "../Empty/Empty"
import style from "./InfoPratique.module.scss"

const InfoPratique = ({h3 , text}) => {
    return(
 
       
        
<div className={style.container}>
<h3>{h3}</h3>
            <Empty />
            <p>{text}</p>
            {h3 === "Réservation" && <Button text="Réserver" />}
</div>

            

    )
}
export default InfoPratique