import React, { useEffect, useState } from 'react';
import style from './Lightbox.module.scss';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import Fermer from "../../../../assets/images/Icons/Fermer.png"
import { ThreeDots } from 'react-loader-spinner'

const Lightbox = ({text , closeLightbox , scroll}) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      if ( text === "Le restaurant" ) {
      try {
        const storage = getStorage();
        const listRef = ref(storage, 'Photothèque/Interieur');
        const res = await listAll(listRef);
        const imageUrls = await Promise.all(res.items.map(async (item) => {
          return {
            url: await getDownloadURL(item),
            alt: item.name,
          };
        }));
        setImages(imageUrls);
        setLoading(false);
      } catch (error) {
        setError("Une erreur s'est produite lors de la récupération des images.");
        setLoading(false);
      }
    } else if ( text === "Nos plats") {
      try {
        const storage = getStorage();
        const listRef = ref(storage, 'Photothèque/Plats');
        const res = await listAll(listRef);
        const imageUrls = await Promise.all(res.items.map(async (item) => {
          return {
            url: await getDownloadURL(item),
            alt: item.name,
          };
        }));
        setImages(imageUrls);
        setLoading(false);
      } catch (error) {
        setError("Une erreur s'est produite lors de la récupération des images.");
        setLoading(false);
      }
    } else if ( text === "Notre équipe") {
      try {
        const storage = getStorage();
        const listRef = ref(storage, 'Photothèque/Equipe');
        const res = await listAll(listRef);
        const imageUrls = await Promise.all(res.items.map(async (item) => {
          return {
            url: await getDownloadURL(item),
            alt: item.name,
          };
        }));
        setImages(imageUrls);
        setLoading(false);
      } catch (error) {
        setError("Une erreur s'est produite lors de la récupération des images.");
        setLoading(false);
      }
    } else if ( text === "L'extérieur du restaurant") {
      try {
        const storage = getStorage();
        const listRef = ref(storage, 'Photothèque/Exterieur');
        const res = await listAll(listRef);
        const imageUrls = await Promise.all(res.items.map(async (item) => {
          return {
            url: await getDownloadURL(item),
            alt: item.name,
          };
        }));
        setImages(imageUrls);
        setLoading(false);
      } catch (error) {
        setError("Une erreur s'est produite lors de la récupération des images.");
        setLoading(false);
      }
    } 
    };

    fetchImages();
  }, [text]);

  return (
    <div className={style.container}>
      {loading ? (
        <div className={style.loading}>
        <ThreeDots 
height="100" 
width="100" 
radius="9"
color="#FFFFFF" 
ariaLabel="three-dots-loading"
wrapperStyle={{}}
wrapperClassName=""
visible={true}
 />
        <div className={style.closeBtn}><img src={Fermer} alt="Fermer le menu"  onClick={() => {
          closeLightbox();
          scroll();
        }} /></div>
        </div>
      ) : error ? (
        <>
        <div><h3>{error}</h3></div>
        <div className={style.closeBtn}><img src={Fermer} alt="Fermer le menu"  onClick={() => {
          closeLightbox();
          scroll();
        }} /></div>
        </>
      ) : (
        <div className={style.contents}>
          <div className={style.closeBtn}><img src={Fermer} alt="Fermer le menu"  onClick={() => {
      closeLightbox();
      scroll();
    }} /></div>
        <h2>{text}</h2>
        <div className={style.imageContainer}>
          {images.map((image, index) => (
            <img key={image.url} src={image.url} alt={image.alt} />
          ))}
        </div>
        </div>
       
      )}
    </div>
  );
}

export default Lightbox;
