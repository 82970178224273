import style from "./Burger.module.scss";
import Fermer from "../../assets/images/Icons/Fermer.png";
import { NavLink } from "react-router-dom";
import Facebook from "../../assets/images/Icons/Facebook.png";
import Instagram from "../../assets/images/Icons/Instagram.png";
import Twitter from "../../assets/images/Icons/Twitter.png";
import Empty from "../Empty/Empty";
import Categorie from "../../pages/Admin/Admin/components/Categorie/Categorie";

const Burger = ({ handle, handle2, type, actif }) => {
  // Numéro de téléphone
  const phoneNumber = "0361125056";

  // Fonction pour gérer l'ouverture de l'application téléphone
  const handleOpenPhoneApp = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className={style.container}>
      {type === "1" ? (
        <>
          <div className={style.contents}>
            <img src={Fermer} alt="Fermer le menu" onClick={handle} />
            <div className={style.menu}>
              <nav className={style.titles}>
                <NavLink onClick={handle} to="/menu">
                  <h2 className={style.firstTitle}>Carte et Menu</h2>
                </NavLink>
                <NavLink onClick={handle} to="/photos">
                  <h2 className={style.secondTitle}>Photos</h2>
                </NavLink>
                <NavLink onClick={handle} to="/contact">
                  <h2 className={style.lastTitle}>Contact</h2>
                </NavLink>
              </nav>
              <button className={style.button} onClick={handleOpenPhoneApp}>
                Réserver
              </button>
              <div className={style.land}>
                <button className={style.button2}>Réserver</button>
                <div className={style.social}>
                  <img src={Facebook} alt="Facebook" />
                  <img src={Instagram} alt="Instagram" />
                  <img src={Twitter} alt="Twitter" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={style.contents2}>
          <img src={Fermer} alt="Fermer le menu" onClick={handle} />
          <div className={style.menu2}>
            <div className={style.adminTitle}>
              <h2>Menu Admin</h2>
              <Empty />
            </div>
            <div className={style.categorieLeftBar}>
              <Categorie nom="Accueil" actif={actif} handle={handle2} />
              <Categorie nom="Modifier le menu" actif={actif} handle={handle2} />
              <Categorie nom="Modifier les horaires" actif={actif} handle={handle2} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Burger;
