import { NavLink, useLocation } from "react-router-dom";
import style from "./Politique.module.scss"
import Empty from "../../components/Empty/Empty";
import Button from "../../components/Button/Button";
const Politique = () => {
    const location = useLocation();
    const {state} = location ?? {};

    return(
    <div className={style.container}>
        {!state && (
            <>
            <h2>Que voulez-vous voir ?</h2>
            <div className={style.btn}>
            <Button text="CGU" link="/Politique-CGU-Cookies-Mention" prop="CGU" />
            <Button text="Politique de Confidentialité" link="/Politique-CGU-Cookies-Mention" prop="Politique" />
            <Button text="Mention légales" link="/Politique-CGU-Cookies-Mention" prop="Mention" />
            <Button text="Politique des cookies" link="/Politique-CGU-Cookies-Mention" prop="Cookie" />
            </div>
            </>
        )}
        {state && state.contenu === "Politique" && (
            <>
                <h2>Politique de Confidentialité</h2>
                <Empty />
                <p>Date de mise à jour: 08/09/2023</p>
                <p>Bienvenue sur le site web du restaurant Au Taquet. Chez Au Taquet, nous nous engageons à protéger votre vie privée et vos données personnelles. Cette politique de confidentialité explique comment nous recueillons, utilisons et protégeons vos informations lorsque vous visitez notre site web.</p>
                <p></p>
                <h3>Collecte et Utilisation des Informations</h3>
                <p>Lorsque vous visitez notre site web, nous pouvons recueillir certaines informations automatiquement, telles que votre adresse IP, le type de navigateur que vous utilisez, les pages que vous consultez, ainsi que la date et l'heure de votre visite. Ces informations nous aident à améliorer notre site et à mieux comprendre les préférences de nos visiteurs.
Si vous choisissez de nous contacter via le formulaire de contact, nous pouvons recueillir des informations personnelles telles que votre nom, votre adresse e-mail et votre numéro de téléphone. Ces informations seront utilisées uniquement pour répondre à votre demande et pour vous fournir les informations que vous avez demandées.</p>
<p></p>
<h3>Cookies</h3>
<p>Notre site peut utiliser des cookies pour améliorer votre expérience en ligne. Les cookies sont de petits fichiers textes stockés sur votre ordinateur lorsque vous visitez notre site. Ils ne contiennent aucune information personnelle identifiable et sont utilisés pour suivre les habitudes de navigation des visiteurs, personnaliser le contenu et analyser l'utilisation du site.</p>
<p></p>
<h3>Partage d’informations</h3>
<p>Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers à des fins de marketing. Cependant, nous pouvons partager vos informations avec des prestataires de services tiers qui nous aident à exploiter notre site web et à fournir nos services. Ces prestataires sont tenus de protéger vos informations et de les utiliser uniquement aux fins pour lesquelles nous les avons partagées.</p>
<p></p>
<h3>Sécurité des données</h3>
<p>Nous prenons des mesures de sécurité appropriées pour protéger vos données contre l'accès non autorisé, la divulgation ou la destruction. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.</p>
<p></p>
<h3>Vos droits</h3>
<p>Vous avez le droit de demander l'accès, la correction ou la suppression de vos informations personnelles que nous détenons. Pour exercer ces droits, veuillez nous contacter à david.ple.62@gmail.com. Nous nous efforcerons de répondre à votre demande dans les délais légaux applicables.</p>
<p></p>
<h3>Modification de la Politique de Confidentialité</h3>
<p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre pour refléter les changements apportés à nos pratiques de collecte et d'utilisation d'informations. Nous vous encourageons à consulter cette page régulièrement pour rester informé de nos pratiques actuelles en matière de confidentialité.</p>
<Empty />
<p>Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à david.ple.62@gmail.com.</p>
          </>
        )}  
        {state && state.contenu === "CGU" && (
            <>
            <h2>Conditions Générales d'Utilisation</h2>
            <Empty />
            <p>Date de mise à jour: 08/09/2023</p>
            <p>Veuillez lire attentivement ces Conditions Générales d'Utilisation ("CGU") avant d'utiliser notre site web Au Taquet (le "Site"). En utilisant le Site, vous acceptez de vous conformer à ces CGU. Si vous n'acceptez pas ces CGU, veuillez ne pas utiliser le Site.</p>
            <p></p>
            <h3>1. Utilisation du Site</h3>
            <p>1.1. Vous devez être âgé d'au moins 18 ans pour utiliser ce Site.</p>
            <p>1.2. Vous acceptez de ne pas utiliser le Site à des fins illégales ou non autorisées.</p>
            <p></p>
            <h3>2. Contenu du Site</h3>
            <p>2.1. Tout le contenu présenté sur le Site, y compris les menus, les images, les textes et les informations, est la propriété exclusive de Au Taquet et est protégé par les lois sur le droit d'auteur. Vous ne pouvez pas copier, reproduire, distribuer ou utiliser de manière non autorisée le contenu du Site.</p>
            <p>2.2. Les informations présentées sur le Site peuvent changer sans préavis. Nous nous réservons le droit de mettre à jour ou de modifier le contenu du Site à tout moment.</p>
            <p></p>
            <h3>3. Formulaire de Contact</h3>
            <p>3.1. Le Site propose un formulaire de contact permettant aux utilisateurs de nous envoyer des messages. En utilisant ce formulaire, vous acceptez de fournir des informations exactes, complètes et à jour.</p>
            <p>3.2. Vous acceptez de n'utiliser le formulaire de contact que dans un but légitime, tel que la prise de réservations ou la communication concernant nos services.</p>
            <p></p>
            <h3>4. Protection de la Vie Privée</h3>
            <p>4.1. Nous collectons, stockons et utilisons vos informations conformément à notre Politique de Confidentialité, que vous pouvez consulter sur <NavLink to="/Politique-CGU-Cookies-Mention" state={{contenu: "Politique"}}>Politique de Confientialité</NavLink>.</p>
            <p></p>
            <h3>5. Liens Externes</h3>
            <p>5.1. Le Site peut contenir des liens vers des sites web tiers. Nous ne sommes pas responsables du contenu de ces sites web tiers ni de leurs pratiques en matière de confidentialité. L'utilisation de ces liens est à vos risques et périls.</p>
            <p></p>
            <h3>6. Exclusion de Responsabilité</h3>
            <p>6.1. Nous ne pouvons pas garantir l'exactitude, la fiabilité ou la disponibilité continue du Site. Nous ne sommes pas responsables des interruptions ou des erreurs sur le Site.</p>
            <p>6.2. En utilisant le Site, vous reconnaissez que vous assumez tous les risques associés à son utilisation et que vous dégagez Au Taquet de toute responsabilité pour tout dommage ou perte résultant de votre utilisation du Site.</p>
            <p></p>
            <h3>7. Modification des CGU</h3>
            <p>7.1. Nous nous réservons le droit de modifier ces CGU à tout moment. Toute modification sera publiée sur cette page. Il est de votre responsabilité de consulter régulièrement les CGU pour rester informé des mises à jour.</p>
            <p></p>
            <Empty />
            <p>En utilisant ce Site, vous acceptez ces CGU. Si vous avez des questions ou des préoccupations concernant ces CGU, veuillez nous contacter à david.ple.62@gmail.com.</p>
            </>
        )}
        {state && state.contenu === "Mention" && (
            <>
            <h2>Mentions légales</h2>
            <Empty />
            <p>Date de mise à jour: 08/09/2023</p>
            <p></p>
            <h3>1. Informations générales</h3>
            <p>Le site web du restaurant Au Taquet est édité par Au Taquet, une société enregistrée au 11 Pl. du Général de Gaulle, 62136 Richebourg.</p>
            <p></p>
            <h3>2. Responsable de la publication</h3>
            <p>Le responsable de la publication du site web est David Ple, joignable à l'adresse électronique suivante : david.ple.62@gmail.com.</p>
            <p></p>
            <h3>3. Menu en ligne</h3>
            <p>Le site web du restaurant Au Taquet propose un service permettant aux visiteurs de consulter le menu en ligne. Les prix et la disponibilité des plats sont indicatifs et peuvent être sujets à des modifications sans préavis.</p>
            <p></p>
            <h3>4. Informations générales</h3>
            <p>Les informations fournies sur ce site web sont destinées à donner un aperçu des services et des activités du restaurant Au Taquet. Elles ne constituent pas un engagement contractuel.</p>
            <p></p>
            <h3>5. Collecte de données personnelles</h3>
            <p>Lorsque vous utilisez le formulaire de contact pour envoyer un message au restaurant Au Taquet, vous consentez à la collecte des données suivantes : nom, prénom, adresse e-mail, et numéro de téléphone. Ces informations sont collectées dans le but de répondre à votre demande et de fournir les informations que vous avez sollicitées. Elles ne seront pas utilisées à des fins de marketing sans votre consentement explicite.</p>
            <p></p>
            <h3>6. Protection des données</h3>
            <p>Le restaurant Au Taquet s'engage à protéger la confidentialité de vos données personnelles. Vos informations ne seront pas vendues, louées ou partagées avec des tiers à des fins commerciales.</p>
            <p></p>
            <h3>7. Sécurité des données</h3>
            <p>Nous prenons des mesures de sécurité appropriées pour protéger vos données contre tout accès non autorisé, divulgation ou perte. Cependant, veuillez noter que la sécurité totale des données sur Internet ne peut être garantie.</p>
            <p></p>
            <h3>8. Droits d'accès et de rectification</h3>
            <p>Vous avez le droit de demander l'accès, la rectification ou la suppression de vos données personnelles détenues par le restaurant Au Taquet. Pour exercer ces droits, veuillez nous contacter à l'adresse électronique suivante : david.ple.62@gmail.com.</p>
            <p></p>
            <p></p>
            <h3>9. Modification des mentions légales</h3>
            <p>Le restaurant Au Taquet se réserve le droit de modifier ces mentions légales à tout moment. Toute modification sera publiée sur cette page. Il est de votre responsabilité de consulter régulièrement les mentions légales pour rester informé des mises à jour.</p>
            <Empty />
            <p>Si vous avez des questions ou des préoccupations concernant ces mentions légales, veuillez nous contacter à david.ple.62@gmail.com.</p>
            </>
        )}
        {state && state.contenu === "Cookie" && (
            <>
            </>
        )}


        </div>
        
    )
}

export default Politique