import style from "./Date.module.scss"
import Closed from "../../../assets/images/Icons/Closed.png"

const Date = ({ jour, fermé, ouverture, fermeture }) => {
  return (
    <div className={style.container}>
      <h4>{jour}</h4>
      {fermé === true ? (
        <>
        <div className={style.contents}>
        <img src={Closed} alt="fermé" />
        </div>
        <div className={style.contents2}>
        <img src={Closed} alt="fermé" />
        </div>
        </>
      ) : (
        <>
        <div className={style.contents}>
        <h5>
          {ouverture}-{fermeture}
        </h5>
        </div>
        <div className={style.contents2}>
        <h5>
          {ouverture}
        </h5>
        <h5>-</h5>
        <h5>{fermeture}</h5>
        </div>
        </>
      )}
    </div>
  );
};

export default Date;
