import { Outlet, useLocation } from "react-router-dom";
import { Suspense, useEffect } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./assets/styles/index.scss"
import "./config"
import { useState } from "react";
import Creation from "./pages/Creation/Creation";

function App() {
  const [wait , setWait] = useState(true)
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const isAdminPage = pathname === "/admin";
  const isConnexionPage = pathname === "/connexion-admin";

  return (
    <div className="app">
      {wait ? (
        <Creation />
      ) : (<>{!isAdminPage && !isConnexionPage && <Header />}

<Suspense>
  <div>
    <Outlet />
  </div>
</Suspense>
{!isAdminPage && !isConnexionPage && <Footer />}</>) }
      
    </div>
  );
}

export default App;
