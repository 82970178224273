import { createBrowserRouter } from "react-router-dom";
import App from "../App";

import Homepage from "../pages/Homepage/Homepage";
import Contact from "../pages/Contact/Contact";
import Photos from "../pages/Photos/Photos";
import Menu from "../pages/Menu/Menu";
import Connexion from "../pages/Admin/Connexion/Connexion";
import Politique from "../pages/Politique/Politique";
import Admin from "../pages/Admin/Admin/Admin";
import Error from "../pages/404/404";
import Creation from "../pages/Creation/Creation";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Creation />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/photos",
        element: <Photos />,
      },
      {
        path: "/menu",
        element: <Menu />,
      },
      {
        path: "/Politique-CGU-Cookies-Mention",
        element: <Politique />,
      },
      {
        path: "/connexion-admin",
        element: <Connexion />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
    ],
  },
]);
