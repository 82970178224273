import { NavLink } from "react-router-dom";
import style from "./Header.module.scss";
import logo from "../../assets/images/logo/logo.png";
import SocialNetwork from "../SocialNetwork/SocialNetwork";
import BurgerIcon from "../../assets/images/Icons/Burger.png"
import { useState } from "react";
import Burger from "../Burger/Burger";

const Header = () => {

    const [burger , setBurger] = useState(false)

    const handleOpenBurger = () => {
        setBurger(true);
    }
    const handleCloseBurger = () => {
        setBurger(false);
    }

    const phoneNumber = "0361125056"; // Numéro de téléphone

    const handleReserver = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // Si l'utilisateur accède au site depuis un téléphone
            window.location.href = `tel:${phoneNumber}`;
        } else {
            // Si l'utilisateur accède au site depuis un ordinateur
            const dummyElement = document.createElement("textarea");
            dummyElement.value = phoneNumber;
            document.body.appendChild(dummyElement);
            dummyElement.select();
            document.execCommand("copy");
            document.body.removeChild(dummyElement);
            alert("Numéro de téléphone copié");
        }
    }

    return (
        <>
        {!burger ? ( <header className={style.container}>
            <div className={style.contents}>
                <NavLink to='/' className={style.linkLogo}>
                <img src={logo} alt="Logo" className={style.logo} />
                </NavLink>
                <nav className={style.navbar}>
                    <NavLink className={style.link} to="/menu">Carte et Menu</NavLink>
                    <NavLink className={style.link} to="/photos">Photos</NavLink>
                    <NavLink className={style.link} to="/contact">Contact</NavLink>
                    <button className={style.button} onClick={handleReserver}>Réserver</button>
                </nav>
                <img src={BurgerIcon} alt="menu" className={style.burger} onClick={handleOpenBurger} />
            </div>
        </header>) : (<Burger handle={handleCloseBurger} type="1" />)}
       
    <SocialNetwork />
    </>
    );
}

export default Header;
