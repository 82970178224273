import React from 'react'
import style from "./Creation.module.scss"
import Logo from "../../assets/images/logo/logo.png"

function Creation() {
  return (
    <div className={style.container}>
        <img className={style.logo} src={Logo} alt="Logo du site" />
        <h1>Nous cuisinons actuellement un site internet aussi délicieux que nos plats, mais il semble que la cuisson prenne un peu plus de temps que prévu.. </h1>
    </div>
  )
}

export default Creation