import style from "./Pannel.module.scss"
import Menu from "../../../../../assets/images/Icons/Menu.png"
import Horaire from "../../../../../assets/images/Icons/Horaire.png"
import Deconnexion from "../../../../../assets/images/Icons/Deconnexion.png"
import Entrées from "../../../../../assets/images/Icons/Entrees.png"
import Plats from "../../../../../assets/images/Icons/Plats.png"
import Desserts from "../../../../../assets/images/Icons/Desserts.png"
import Cocktail from "../../../../../assets/images/Icons/Cocktail.png"

const Pannel = ({contenu , handle, actif}) => {

    

    return(
       <>
            {contenu === "Modifier le menu" && (
                 <div className={`${style.container}`} onClick={() => handle(contenu)}>
                    <img src={Menu} alt="Menu" />
                <p>Modifier le menu</p>
                </div>
            )}
            {contenu === "Modifier les horaires" && (
                 <div className={`${style.container}`} onClick={() => handle(contenu)}>
                <img src={Horaire} alt="Horaire" />
                <p>Modifier les horaires</p>
                </div>
            )}
            {contenu === "Deconnexion" && (
                 <div className={`${style.container}`} onClick={() => handle(contenu)}>
                <img src={Deconnexion} alt="Déconnexion" />
                <p>Déconnexion</p>
                </div>
            )}
            {contenu === "Cocktails" && (
                 <div className={`${style.container} ${actif === contenu && style.actif}`} onClick={() => handle(contenu)}>
                <img src={Cocktail} alt="Cocktails" />
                <p>Cocktails</p>
                </div>
            )}
            {contenu === "Entrées" && (
                 <div className={`${style.container} ${actif === contenu && style.actif}`} onClick={() => handle(contenu)}>
                <img src={Entrées} alt="Entrées" />
                <p>Entrées</p>
                </div>
            )}
            {contenu === "Plats" && (
                <div className={`${style.container} ${actif === contenu && style.actif}`} onClick={() => handle(contenu)}>
                <img src={Plats} alt="Plats" />
                <p>Plats</p>
                </div>
            )}
            {contenu === "Desserts" && (
                 <div className={`${style.container} ${actif === contenu && style.actif}`} onClick={() => handle(contenu)}>
                <img src={Desserts} alt="Desserts" />
                <p>Desserts</p>
                </div>
            )}
        </>
    )
}

export default Pannel