import React, { useEffect, useState } from "react";
import style from "./Tableau.module.scss";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Modifier from "../../../../../assets/images/Icons/Modifier.png";
import Supprimer from "../../../../../assets/images/Icons/Supprimer.png";
import Valider from "../../../../../assets/images/Icons/Valider.png";
import Annuler from "../../../../../assets/images/Icons/Annuler.png";
import Ajouter from "../../../../../assets/images/Icons/Ajouter.png";
import AvecAlcool from "../../../../../assets/images/Icons/Avec-Alcool.png"
import SansAlcool from "../../../../../assets/images/Icons/Sans-Alcool.png"

const Tableau = ({ content }) => {
  const [menuData, setMenuData] = useState([]);
  const [modifier, setModifier] = useState(false);
  const [selectName, setSelectName] = useState("");
  const [selectIngredients, setSelectIngredients] = useState([]);
  const [selectPrice, setSelectPrice] = useState("");
  const [oldName, setOldName] = useState("");
  const [ajouterIngredient, setAjouterIngredient] = useState(false);
  const [nouvelIngredient, setNouvelIngredient] = useState("");
  const [ajouterPlat, setAjouterPlat] = useState(false);
  const [ingredientCount, setIngredientCount] = useState(0);
  const [addName, setAddName] = useState("");
  const [addIngredients, setAddIngredients] = useState([]);
  const [addPrix, setAddPrix] = useState("");
  const [cocktailSemaine, setcocktailSemaine] = useState([])
  const [platSemaine, setPlatSemaine] = useState([])
  const [isCocktailDuJour, setIsCocktailDuJour] = useState(false)
  const [selectedOption, setSelectedOption] = useState("non")
  const [selectAlcool, setSelectAlcool] = useState('')
  const [question , setQuestion] = useState(false)
  
  console.log(content);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = firebase.firestore();
        const menuRef = db.collection("Menu").doc(content);
        const doc = await menuRef.get();

        if (doc.exists) {
          const data = doc.data();

          const menuArray = Object.values(data);

          const menuItemsWithId = menuArray.map((plat, index) => {
            return {
              id: Object.keys(data)[index],
              ...plat,
            };
          });

          menuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));

          setMenuData(menuItemsWithId);
          setModifier(false);
          setAjouterPlat(false)
          setIngredientCount(0)
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, [content]);

  useEffect(() => {
    const fetchCocktailDuJour = async () => {
      try {
        const db = firebase.firestore();
        const cocktailDuJourRef = db.collection("Menu").doc("Cocktail de la semaine");
        const cocktailDuJourDoc = await cocktailDuJourRef.get();
  
        if (cocktailDuJourDoc.exists) {
          const cocktailData = cocktailDuJourDoc.data();
  
          const cocktailArray = Object.values(cocktailData);
  
          const cocktailItemsWithId = cocktailArray.map((cocktail, index) => {
            return {
              id: Object.keys(cocktailData)[index],
              ...cocktail,
            };
          });
  
          cocktailItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
  
          setcocktailSemaine(cocktailItemsWithId);

          setModifier(false);
          setAjouterPlat(false)
          setIngredientCount(0)
        } else {
          console.log("Le document Cocktail du jour n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du cocktail du jour :", error);
      }
    };
  
    fetchCocktailDuJour(); 
  }, []);

  useEffect(() => {
    const fetchPlatDuJour = async () => {
      try {
        const db = firebase.firestore();
        const menuRef = db.collection("Menu").doc("Plat de la semaine");
        const doc = await menuRef.get();

        if (doc.exists) {
          const data = doc.data();

          const menuArray = Object.values(data);

          const menuItemsWithId = menuArray.map((plat, index) => {
            return {
              id: Object.keys(data)[index],
              ...plat,
            };
          });

          menuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));

          setPlatSemaine(menuItemsWithId);
          setModifier(false);
          setAjouterPlat(false)
          setIngredientCount(0)
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchPlatDuJour();
  }, []);

  const formatPrice = (price) => {
    price = price.replace(',', '.');

    const formattedPrice = parseFloat(price).toFixed(2);
    return formattedPrice;
  };
  
  

  const handleDelete = async (platId) => {
    try {
      // Demandez confirmation avant de supprimer
      const confirmDelete = window.confirm(
        `Êtes-vous sûr de vouloir supprimer le plat "${platId}" ?`
      );
      if (!confirmDelete) {
        return;
      }

      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc(content);

      await menuRef.update({
        [platId]: firebase.firestore.FieldValue.delete(),
      });

      setMenuData((prevMenuData) =>
        prevMenuData.filter((plat) => plat.id !== platId)
      );
    } catch (error) {
      console.error("Erreur lors de la suppression du plat :", error);
    }
  };
  const handleDelete2 = async (platId) => {
    try {
      // Demandez confirmation avant de supprimer
      const confirmDelete = window.confirm(
        `Êtes-vous sûr de vouloir supprimer le plat "${platId}" ?`
      );
      if (!confirmDelete) {
        return;
      }
  
      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc("Cocktail de la semaine");
  
      await menuRef.update({
        [platId]: firebase.firestore.FieldValue.delete(),
      });
  
      // Rafraîchissez la liste après la suppression
      const updatedMenuRef = db.collection("Menu").doc("Cocktail de la semaine");
      const updatedDoc = await updatedMenuRef.get();
  
      if (updatedDoc.exists) {
        const updatedData = updatedDoc.data();
  
        const updatedMenuArray = Object.values(updatedData);
  
        const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
          return {
            id: Object.keys(updatedData)[index],
            ...plat,
          };
        });
  
        updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
        setcocktailSemaine(updatedMenuItemsWithId);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du plat :", error);
    }
  };
  
  const handleDelete3 = async (platId) => {
    try {
      // Demandez confirmation avant de supprimer
      const confirmDelete = window.confirm(
        `Êtes-vous sûr de vouloir supprimer le plat "${platId}" ?`
      );
      if (!confirmDelete) {
        return;
      }
  
      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc("Plat de la semaine");
  
      await menuRef.update({
        [platId]: firebase.firestore.FieldValue.delete(),
      });
  
      // Rafraîchissez la liste après la suppression
      const updatedMenuRef = db.collection("Menu").doc("Plat de la semaine");
      const updatedDoc = await updatedMenuRef.get();
  
      if (updatedDoc.exists) {
        const updatedData = updatedDoc.data();
  
        const updatedMenuArray = Object.values(updatedData);
  
        const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
          return {
            id: Object.keys(updatedData)[index],
            ...plat,
          };
        });
  
        updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
        setPlatSemaine(updatedMenuItemsWithId);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du plat :", error);
    }
  };
  

  const handleModif = async (plat , content) => {
    setModifier(true);
    setOldName(plat.id);
    setSelectName(plat.id);
    setSelectAlcool(plat.Alcool);
    setSelectIngredients(plat.ingrédients);
    setSelectPrice(plat.prix);
    setIsCocktailDuJour(content);
    console.log(content);
  };

  const handleQuestion = () => {
    setQuestion(true) 
  }

  const handleValider = async () => {
    
    try {
      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc(content);
     

     
      if (selectName !== oldName) {
     
       
        await menuRef.update({
          [oldName]: firebase.firestore.FieldValue.delete(),
        });
      }

      const updatedData = {
        [selectName]: {
          ingrédients: selectIngredients,
          prix: formatPrice(selectPrice), 
          Alcool: selectAlcool ? selectAlcool : false
        },
      };
  
      await menuRef.update(updatedData);
      

    
      setOldName(selectName);

   
      const updatedMenuRef = db.collection("Menu").doc(content);
      const updatedDoc = await updatedMenuRef.get();

      if (updatedDoc.exists) {
        const updatedData = updatedDoc.data();
       
        const updatedMenuArray = Object.values(updatedData);

        const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
          return {
            id: Object.keys(updatedData)[index],
            ...plat,
          };
        });

        updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
        setMenuData(updatedMenuItemsWithId);
   
      }

     handleQuestion()

    } catch (error) {
      console.error("Erreur lors de la modification du plat :", error);
    }
  };

  const handleValider2 = async () => {
    try {
      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc("Cocktail de la semaine");

      

   
      if (selectName !== oldName) {
      
        await menuRef.update({
          [oldName]: firebase.firestore.FieldValue.delete(),
        });
      }



      const updatedData = {
        [selectName]: {
          ingrédients: selectIngredients,
          prix: formatPrice(selectPrice), 
          Alcool: true,
        },
      };
  
      await menuRef.update(updatedData);


    
      setOldName(selectName);

     
      const updatedMenuRef = db.collection("Menu").doc("Cocktail de la semaine");
      const updatedDoc = await updatedMenuRef.get();

      if (updatedDoc.exists) {
        const updatedData = updatedDoc.data();

        const updatedMenuArray = Object.values(updatedData);

        const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
          return {
            id: Object.keys(updatedData)[index],
            ...plat,
          };
        });

        updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
        setcocktailSemaine(updatedMenuItemsWithId);
      }

      handleAnnuler();
    } catch (error) {
      console.error("Erreur lors de la modification du plat :", error);
    }
  };

  const handleValider3 = async () => {
    try {
      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc("Plat de la semaine");

    
      if (selectName !== oldName) {
     
        await menuRef.update({
          [oldName]: firebase.firestore.FieldValue.delete(),
        });
      }

      const updatedData = {
        [selectName]: {
          ingrédients: selectIngredients,
          prix: formatPrice(selectPrice), 
        },
      };
  
      await menuRef.update(updatedData);

      
      setOldName(selectName);


      const updatedMenuRef = db.collection("Menu").doc("Plat de la semaine");
      const updatedDoc = await updatedMenuRef.get();

      if (updatedDoc.exists) {
        const updatedData = updatedDoc.data();

        const updatedMenuArray = Object.values(updatedData);

        const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
          return {
            id: Object.keys(updatedData)[index],
            ...plat,
          };
        });

        updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
        setPlatSemaine(updatedMenuItemsWithId);
      }

      handleAnnuler();
    } catch (error) {
      console.error("Erreur lors de la modification du plat :", error);
    }
  };

  const handleAnnuler = () => {
    setModifier(false);
    setAjouterIngredient(false);
    setSelectName("");
    setSelectIngredients([]);
    setSelectPrice("");
  };

  const handleAjouterIngredient = () => {
    setAjouterIngredient(true);
  };

  const handleAnnulerAjout = () => {
    setAjouterIngredient(false);
    setNouvelIngredient("");
  };

  const handleValiderAjout = async () => {
    try {
      if (nouvelIngredient) {
        const newIngredients = [...selectIngredients, nouvelIngredient];
        setSelectIngredients(newIngredients);

        const db = firebase.firestore();
        const menuRef = db.collection("Menu").doc(content);
        await menuRef.update({
          [selectName]: {
            ingrédients: newIngredients,
            prix: selectPrice,
          },
        });

        const updatedMenuRef = db.collection("Menu").doc(content);
        const updatedDoc = await updatedMenuRef.get();

        if (updatedDoc.exists) {
          const updatedData = updatedDoc.data();

          const updatedMenuArray = Object.values(updatedData);

          const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
            return {
              id: Object.keys(updatedData)[index],
              ...plat,
            };
          });

          updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
          setMenuData(updatedMenuItemsWithId);
        }

        setNouvelIngredient("");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'ingrédient :", error);
    }
  };

  const handleAjouterPlat = () => {
    setAjouterPlat(true);
  };

  const handleFermerAjouterPlat = () => {
    setAjouterPlat(false)
    setIngredientCount(0)
  }

  const addIngredientInput = () => {
    setIngredientCount(ingredientCount + 1);
    setAddIngredients([...addIngredients, ""]);
  };

  const removeIngredientInput = (key) => {
    const newIngredientCount = ingredientCount - 1;
    setIngredientCount(newIngredientCount);
    const updatedAddIngredients = addIngredients.filter((_, index) => index !== key);
    setAddIngredients(updatedAddIngredients);
  };

  const handleValiderAjouter = async () => {
    try {
      if (addName && addIngredients.length > 0 && addPrix) {
        const db = firebase.firestore();
        const menuRef = db.collection("Menu").doc(content);
  
        const newPlatData = {
          ingrédients: addIngredients,
          prix: formatPrice(addPrix), // Utilisez la fonction de formatage ici
        };
  
        await menuRef.update({
          [addName]: newPlatData,
        });
  
        // Rafraîchissez le contenu Firestore après l'ajout
        const updatedMenuRef = db.collection("Menu").doc(content);
        const updatedDoc = await updatedMenuRef.get();
  
        if (updatedDoc.exists) {
          const updatedData = updatedDoc.data();
  
          const updatedMenuArray = Object.values(updatedData);
  
          const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
            return {
              id: Object.keys(updatedData)[index],
              ...plat,
            };
          });
  
          updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
          setMenuData(updatedMenuItemsWithId);
        }
  
        // Réinitialisez les champs du formulaire après l'ajout
        setAjouterPlat(false);
        setAddName("");
        setAddIngredients([]);
        setAddPrix("");
        setIngredientCount(0);
      } else {
        console.error("Veuillez remplir tous les champs avant d'ajouter le plat.");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du plat :", error);
    }
  };

  const handleValiderAjouter2 = async () => {
    try {
      if (addName && addIngredients.length > 0 && addPrix) {
        const db = firebase.firestore();
        const menuRef = db.collection("Menu").doc("Cocktail de la semaine");
  
        const newPlatData = {
          ingrédients: addIngredients,
          prix: formatPrice(addPrix), // Utilisez la fonction de formatage ici
        };
  
        await menuRef.set({
          [addName]: newPlatData,
        });
  
        // Rafraîchissez le contenu Firestore après l'ajout
        const updatedMenuRef = db.collection("Menu").doc("Cocktail de la semaine");
        const updatedDoc = await updatedMenuRef.get();
  
        if (updatedDoc.exists) {
          const updatedData = updatedDoc.data();
  
          const updatedMenuArray = Object.values(updatedData);
  
          const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
            return {
              id: Object.keys(updatedData)[index],
              ...plat,
            };
          });
  
          updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
          setcocktailSemaine(updatedMenuItemsWithId);
        }
  
        // Réinitialisez les champs du formulaire après l'ajout
        setAjouterPlat(false);
        setAddName("");
        setAddIngredients([]);
        setAddPrix("");
        setIngredientCount(0);
      } else {
        console.error("Veuillez remplir tous les champs avant d'ajouter le plat.");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du plat :", error);
    }
  };
  
  

  const handleValiderAjouter3 = async () => {
    try {
      if (addName && addIngredients.length > 0 && addPrix) {
        const db = firebase.firestore();
        const menuRef = db.collection("Menu").doc("Plat de la semaine");
  
        const newPlatData = {
          ingrédients: addIngredients,
          prix: formatPrice(addPrix),
        };
  
        await menuRef.set({
          [addName]: newPlatData,
        });
  
        // Rafraîchissez le contenu Firestore après l'ajout
        const updatedMenuRef = db.collection("Menu").doc("Plat de la semaine");
        const updatedDoc = await updatedMenuRef.get();
  
        if (updatedDoc.exists) {
          const updatedData = updatedDoc.data();
  
          const updatedMenuArray = Object.values(updatedData);
  
          const updatedMenuItemsWithId = updatedMenuArray.map((plat, index) => {
            return {
              id: Object.keys(updatedData)[index],
              ...plat,
            };
          });
  
          updatedMenuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
          setPlatSemaine(updatedMenuItemsWithId);
        }
  
        // Réinitialisez les champs du formulaire après l'ajout
        setAjouterPlat(false);
        setAddName("");
        setAddIngredients([]);
        setAddPrix("");
        setIngredientCount(0);
      } else {
        console.error("Veuillez remplir tous les champs avant d'ajouter le plat.");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du plat :", error);
    }
  };
  
  
  
  const handleToggleAlcool = async (platId, currentAlcoolValue) => {
    try {
      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc(content);

      // Inverser la valeur de l'attribut "Alcool"
      const newAlcoolValue = !currentAlcoolValue;

      await menuRef.update({
        [platId]: {
          ...menuData.find((plat) => plat.id === platId),
          Alcool: newAlcoolValue,
        },
      });


      setMenuData((prevMenuData) =>
        prevMenuData.map((plat) =>
          plat.id === platId ? { ...plat, Alcool: newAlcoolValue } : plat
        )
      );
    } catch (error) {
      console.error("Erreur lors de la modification de l'attribut Alcool :", error);
    }
  };

  const handleToggleAlcool2 = async (cocktailId, currentAlcoolValue) => {
    try {
      const db = firebase.firestore();
      const menuRef = db.collection("Menu").doc("Cocktail de la semaine");
  
      // Inverser la valeur de l'attribut "Alcool"
      const newAlcoolValue = !currentAlcoolValue;
  
      await menuRef.update({
        [cocktailId]: {
          ...cocktailSemaine.find((cocktail) => cocktail.id === cocktailId), // Utilisez cocktailSemaine au lieu de menuData
          Alcool: newAlcoolValue,
        },
      });
  
      setcocktailSemaine((prevCocktailSemaine) =>
        prevCocktailSemaine.map((cocktail) =>
          cocktail.id === cocktailId ? { ...cocktail, Alcool: newAlcoolValue } : cocktail
        )
      );
    } catch (error) {
      console.error("Erreur lors de la modification de l'attribut Alcool :", error);
    }
  };
  
  

  return (
    <div className={style.container}>
      
        
      <div className={style.top}>
        {modifier && <h3>{selectName} ({content})</h3>}
        {ajouterPlat && <h3>Ajouter dans {content}</h3>}
        {ajouterPlat && content === "Cocktails" &&
        <div className={style.addSemaine}>
        <p>{content} de la semaine ?</p>
        <select
          name=""
          id=""
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="non">Non</option>
          <option value="oui">Oui</option>
        </select>
      </div>
       }
        {ajouterPlat && content === "Plats" &&
        <div className={style.addSemaine}>
        <p>{content} de la semaine ?</p>
        <select
          name=""
          id=""
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="non">Non</option>
          <option value="oui">Oui</option>
        </select>
      </div>
       }
        {!ajouterPlat && !modifier && <h3>{content}</h3>}
        {!modifier && !ajouterPlat && (
          <img src={Ajouter} alt="Ajouter" onClick={handleAjouterPlat} />
        )}
      </div>
      {question ? (
<div className={style.questionContainer}>
<h2>Question:</h2>

<div>
  {content === "Cocktails" && (
    <>
    <div>
    <p>Est-ce un cocktail alcoolisé ?</p>
  <select name="question1">
    <option value="">Oui</option>
    <option value="">Non</option>
  </select>
  </div>
  <div>
    <p>Est-ce un cocktail sucré ou plutôt sec ?</p>
  <select name="question2">
    <option value="">Sucré</option>
    <option value="">Sec</option>
  </select>
  </div>
  <div>
  <p>Est-ce un cocktail classique ou créatif ?</p>
  <select name="question3">
    <option value="">Classique</option>
    <option value="">Créatif</option>
  </select>
  </div>
  </>
  )}
  {content === "Entrées" && (
    <>
    <div>
    <p>Est-ce une entrée légère ou consistante ?</p>
  <select name="question1">
    <option value="">Légère</option>
    <option value="">Consistante</option>
  </select>
  </div>
  <div>
    <p>Est-ce une entrée végétarienne ?</p>
  <select name="question2">
    <option value="">Oui</option>
    <option value="">Non</option>
  </select>
  </div>
  <div>
  <p>Est-ce une entrée chaude ou froide ?</p>
  <select name="question3">
    <option value="">Chaude</option>
    <option value="">Froide</option>
  </select>
  </div>
  </>
  )}
  {content === "Plats" && (
    <>
    <div>
    <p>Est-ce un plat léger ou consistant ?</p>
  <select name="question1">
    <option value="">Léger</option>
    <option value="">Consistant</option>
  </select>
  </div>
  <div>
    <p>Est-ce un plat végétarien ?</p>
  <select name="question2">
    <option value="">Oui</option>
    <option value="">Non</option>
  </select>
  </div>
  <div>
  <p>Est-ce un plat chaud ou froid ?</p>
  <select name="question3">
    <option value="">Chaud</option>
    <option value="">Froid</option>
  </select>
  </div>
  </>
  )}
  {content === "Desserts" && (
    <>
    <div>
    <p>Est-ce un dessert qui s'accompagne bien avec un café ?</p>
  <select name="question1">
    <option value="">Oui</option>
    <option value="">Non</option>
  </select>
  </div>
  <div>
    <p>Est-ce un dessert alcoolisé ?</p>
  <select name="question2">
    <option value="">Oui</option>
    <option value="">Non</option>
  </select>
  </div>
  <div>
  <p>Est-ce un dessert froid ?</p>
  <select name="question3">
    <option value="">Oui</option>
    <option value="">Non</option>
  </select>
  </div>
  </>
  )}
  
</div>
</div>
) : (
  <>
      {modifier ? (
        <div className={style.platModifier}>
          <input
            className={style.nomModif}
            value={selectName}
            placeholder="Nom du plat"
            onChange={(e) => setSelectName(e.target.value)}
          />
          <div className={style.emptyVertical}></div>
          <div className={style.ingredientModif}>
            {ajouterIngredient ? (
              <div className={style.contentAjout}>
                <input
                  type="text"
                 
                  placeholder="Nom de l'ingrédient"
                  value={nouvelIngredient}
                  onChange={(e) => setNouvelIngredient(e.target.value)}
                />
               
                <img
                  src={Valider}
                  alt="Valider Ajout"
                  onClick={handleValiderAjout}
                />
                <img
                  src={Annuler}
                  alt="Annuler Ajout"
                  onClick={handleAnnulerAjout}
                />
              </div>
            ) : (
              <img
                src={Ajouter}
                alt="Ajouter"
                onClick={handleAjouterIngredient}
              />
            )}
            <div className={style.contents}>
              {selectIngredients.map((ingredient, index) => (
                <div className={style.content} key={index}>
                  <input
                    value={ingredient}
                    className={style.ingredientModif}
                    placeholder="Nom de l'ingrédient"
                    onChange={(e) => {
                      const newIngredients = [...selectIngredients];
                      newIngredients[index] = e.target.value;
                      setSelectIngredients(newIngredients);
                    }}
                  />
                  <img
                    src={Supprimer}
                    alt="Supprimer ingrédient"
                    onClick={() => {
                      const newIngredients = [...selectIngredients];
                      newIngredients.splice(index, 1);
                      setSelectIngredients(newIngredients);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={style.emptyVertical}></div>
          <input
            className={style.prixModif}
            value={selectPrice}
            placeholder="Prix du plat"
            onChange={(e) => setSelectPrice(e.target.value)}
          />
          <div className={style.emptyVertical}></div>
          <div className={style.iconsModif}>

{isCocktailDuJour === "Cocktail" && <img src={Annuler} alt="Valider" onClick={handleValider2} />  }
{isCocktailDuJour === "Plat" && <img src={Valider} alt="Valider" onClick={handleValider3} />}
            {isCocktailDuJour === false && <img src={Valider} alt="Valider" onClick={handleValider} /> }
            <img src={Annuler} alt="Annuler" onClick={handleAnnuler} />
          </div>
        </div>
      ) : (
        <>
          {ajouterPlat ? (
  <div className={style.platModifier}>
    <input
  type="text"
  placeholder="Nom du plat"
  value={addName}
  onChange={(e) => setAddName(e.target.value)}
/>
    <div className={style.emptyVertical}></div>
    <div className={style.ingredientAddContainer}>
    <div className={style.ingredientAdd}>
    {Array.from({ length: ingredientCount + 1 }).map((_, index) => (
  <div key={index}>
    <input
      type="text"
      placeholder="Ingrédient"
      value={addIngredients[index] || ''}
      onChange={(e) => {
        const newIngredients = [...addIngredients];
        newIngredients[index] = e.target.value;
        setAddIngredients(newIngredients);
      }}
    />
    {index >= 1 && (
      <img
        src={Supprimer}
        alt="Supprimer ingrédient"
        onClick={() => removeIngredientInput(index)}
       
      />
    )}
  </div>
))}
      <img
      src={Ajouter}
      alt="Ajouter un ingrédient"
      onClick={addIngredientInput}
    
    />
    </div>
    
    </div>
    <div className={style.emptyVertical}></div>
    <input
  type="text"
  placeholder="Prix du plat"
  value={addPrix}
  onChange={(e) => setAddPrix(e.target.value)}
/>
    <div className={style.emptyVertical}></div>
    <div className={style.iconsModif}>
      
    {selectedOption === 'non' &&  <img src={Valider} alt="Valider Ajout" onClick={handleValiderAjouter} /> }

{selectedOption === "oui" && content === "Cocktails" && <img src={Valider} alt="Valider Ajout" onClick={handleValiderAjouter2} />}

{selectedOption === "oui" && content === "Plats" && <img src={Valider} alt="Valider Ajout" onClick={handleValiderAjouter3} />}

<img src={Annuler} alt="Annuler Ajout" onClick={handleFermerAjouterPlat} />
    </div>
  </div>
) : (
            <>
            {content === "Cocktails" && (
  <>
    <h4 className={style.sousTitre}>Cocktail de la semaine</h4>
    {cocktailSemaine.length === 0 ? (
      <p className={style.noneSemaine}>Pas encore de cocktail de la semaine...</p>
    ) : (
      cocktailSemaine.map((cocktail) => (
        <div className={style.plat} key={cocktail.id}>
        <p className={style.nom}>{cocktail.id}</p>
        <p className={style.ingredients}>
          {cocktail.ingrédients.join(", ")}
        </p>
        <p className={style.prix}>{cocktail.prix}€</p>
        <div className={style.icons}>
          <img
            src={Modifier}
            alt="Modifier"
            onClick={() => handleModif(cocktail , "Cocktail")}
          />
          <img
            src={Supprimer}
            alt="Supprimer"
            onClick={() => handleDelete2(cocktail.id)}
          />
          {content === "Cocktails" &&  <>
                {cocktail.Alcool === true ? (
                  <img
                    src={AvecAlcool}
                    alt="Avec Alcool"
                    onClick={() => handleToggleAlcool2(cocktail.id, cocktail.Alcool)}
                  />
                ) : (
                  <img
                    src={SansAlcool}
                    alt="Sans alcool"
                    onClick={() => handleToggleAlcool2(cocktail.id, cocktail.Alcool)}
                  />
                )}
              </>  }
        </div>
        </div>
      ))
    )}
  </>
)}
           {content === "Plats" && (
  <>
    <h4 className={style.sousTitre}>Plat de la semaine</h4>
    {platSemaine.length === 0 ? (
      <p className={style.noneSemaine}>Pas encore de plat de la semaine...</p>
    ) : (
      platSemaine.map((cocktail) => (
        <div className={style.plat} key={cocktail.id}>
        <p className={style.nom}>{cocktail.id}</p>
        <p className={style.ingredients}>
          {cocktail.ingrédients.join(", ")}
        </p>
        <p className={style.prix}>{cocktail.prix}€</p>
        <div className={style.icons}>
          <img
            src={Modifier}
            alt="Modifier"
            onClick={() => handleModif(cocktail , "Plat")}
          />
          <img
            src={Supprimer}
            alt="Supprimer"
            onClick={() => handleDelete3(cocktail.id)}
          />
          {content === "Cocktails" &&  <>
                {cocktail.Alcool === true ? (
                  <img
                    src={AvecAlcool}
                    alt="Avec Alcool"
                    onClick={() => handleToggleAlcool2(cocktail.id, cocktail.Alcool)}
                  />
                ) : (
                  <img
                    src={SansAlcool}
                    alt="Sans alcool"
                    onClick={() => handleToggleAlcool2(cocktail.id, cocktail.Alcool)}
                  />
                )}
              </>  }
        </div>
        </div>
      ))
    )}
  </>
)}

             <h4 className={style.sousTitre}>{content}</h4>
            {menuData.map((plats) => (
            <div className={style.plat} key={plats.id}>
              <p className={style.nom}>{plats.id}</p>
              <p className={style.ingredients}>
                {plats.ingrédients.join(", ")}
              </p>
               
              <p className={style.prix}>{plats.prix}€</p>
              <div className={style.icons}>
                <img
                  src={Modifier}
                  alt="Modifier"
                  onClick={() => handleModif(plats , false)}
                />
                <img
                  src={Supprimer}
                  alt="Supprimer"
                  onClick={() => handleDelete(plats.id)}
                />
{content === "Cocktails" &&  <>
                {plats.Alcool === true ? (
                  <img
                    src={AvecAlcool}
                    alt="Avec Alcool"
                    onClick={() => handleToggleAlcool(plats.id, plats.Alcool)}
                  />
                ) : (
                  <img
                    src={SansAlcool}
                    alt="Sans alcool"
                    onClick={() => handleToggleAlcool(plats.id, plats.Alcool)}
                  />
                )}
              </>  }

              </div>
            </div>
          ))}
            </>
          )}
          
        </>
        
      )}
      </>
)}
    </div>
  );
};

export default Tableau;
