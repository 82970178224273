import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/auth'
import 'firebase/compat/firestore'

export const firebaseConfig = {
  apiKey: "AIzaSyB5tlPseT-E5N2bsHMVVsh2A8mFszUdNvs",
  authDomain: "le-jardin-gourmand.firebaseapp.com",
  projectId: "le-jardin-gourmand",
  storageBucket: "le-jardin-gourmand.appspot.com",
  messagingSenderId: "782751685093",
  appId: "1:782751685093:web:c893d21e07167497c0b70c"
};

  if(!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }