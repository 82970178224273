import { useState } from "react";
import style from "./Pictures.module.scss"
import Lightbox from "../Lightbox/Lightbox";

const Pictures = ({picture , text , photos , scroll , noscroll}) => {



    const [lightboxOpen, setLightboxOpen] = useState(false);
 
    const closeLightbox = () => {
        setLightboxOpen(false);
      };

    return(
        <>
        {lightboxOpen && (
        <Lightbox text={text} closeLightbox={closeLightbox} scroll={scroll} />
      )}
        <div className={style.container} onClick={() => {setLightboxOpen(true); noscroll();}}>
            <img className={style.pic} src={picture} alt={text} />
            <h3>{text}</h3>
        </div>
        </>
    )
}
export default Pictures