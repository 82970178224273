import React from "react";
import Logo from "../../assets/images/logo/logo.png";
import style from "./Wallpaper.module.scss";

const Wallpaper = ({ picture, text, logo,  }) => {
  const pictureClassName = `${style[picture]} ${style.container}`;



  return (
    <div
      className={pictureClassName}>
      {!logo ? (
        <h1 className={style.title2}>{text}</h1>
      ) : (
        <>
          <img className={style.logo} src={Logo} alt="" />
          <h1 className={style.title}>{text}</h1>
        </>
      )}
    </div>
  );
};

export default Wallpaper;
