import React from "react";
import Wallpaper from "../../components/Wallpaper/Wallpaper";
import style from "./Homepage.module.scss";
import Picture from "../../assets/images/Divers/Decoration_Home.webp"
import Button from "../../components/Button/Button";
import DoubleTitre from "../../components/DoubleTitre/DoubleTitre";
import InfoPratique from "../../components/InfoPratique/InfoPratique";
import Horaire from "../../components/Horaire/Horaire";
import Empty from "../../components/Empty/Empty";


const Homepage = () => {
 
  

  return (
    <div className={style.container}>
      <Wallpaper
        picture="Homepage"
        text="Savourez la vie Au Taquet ! "
        logo={true}
      />
      <div className={style.body}>
      <div className={style.accueil}>
       <DoubleTitre h3="Bienvenue chez" h2="Au Taquet" />
        <div className={style.contentsAccueil}>
            <img className={style.pictureAccueil} src={Picture} alt="Restaurant" />
            <div className={style.teamText}>
            <h4 className={style.titleAccueil}>Le mot de l'équipe</h4>
            <p>Chers clients et amoureux de la bonne cuisine, <br />
C'est avec une immense joie que nous vous accueillons au restaurant Au Taquet. Notre équipe dévouée travaille chaque jour avec passion pour vous offrir une expérience culinaire exceptionnelle, alliant saveurs exquises et ambiance chaleureuse. <br /> <br />

Nous tenons à vous remercier pour votre soutien continu. Votre présence parmi nous nous inspire à évoluer et à perfectionner sans cesse notre art culinaire. N'hésitez pas à nous faire part de vos commentaires et suggestions, car votre satisfaction est notre priorité absolue.</p>
<Empty />
<Button text="Découvrir notre carte" link="/menu"/>
            </div>
        </div>
      </div>
      
    </div>
    <div className={style.greenBackground}>
        <div className={style.body} style={{marginTop: "5vh"}}>
<DoubleTitre h3="Au Taquet" h2="Infos pratiques" />
<div className={style.info}>
<div className={style.leftInfo}>

<InfoPratique h3="Nouveauté" text="Venez découvrir notre nouveau plat l’Éclatante Fusion Marine : Une symphonie audacieuse de fruits de mer fraîchement pêchés, sublimée par une touche exotique de citron vert et de coriandre." />
<InfoPratique h3="Carte de fidélité" text="Découvrez notre carte de fidélité exclusive, conçue pour récompenser nos clients les plus fidèles. " />
<InfoPratique h3="Réservation" text="Nous vous invitons à réserver votre table au Jardin Gourmand. En effectuant une réservation, vous garantissez une place à votre nom et vous nous aidez à vous offrir un meilleur service." />
      </div> 
      <div className={style.rightInfo}>
      <InfoPratique h3="Accès handicapé" text="Nous avons un accès facile et adapté aux personnes à mobilité réduite." />
<InfoPratique h3="Places de parking" text="Nous mettons à disposition des places de parking réservées." />
<InfoPratique h3="Micro-onde" text="Nous mettons à votre disposition un four à micro-ondes afin de chauffer les repas et les biberons." />
        </div> 
      </div>  
    
      <Horaire />
      </div>
      </div>
    </div>
  );
};

export default Homepage;
