import { NavLink } from "react-router-dom";
import style from "./SocialNetwork.module.scss";
import Facebook from "../../assets/images/Icons/Facebook.png";
import Twitter from "../../assets/images/Icons/Twitter.png";
import Instagram from "../../assets/images/Icons/Instagram.png";

const Social_Network = () => {
  return (
    <div className={style.container}>
      <div className={style.contents}>
      <div className={style.empty}></div>
      <NavLink className={style.link} to="">
        <img className={style.icon} src={Facebook} alt="Icon Facebook" />
      </NavLink>
      <NavLink className={style.link} to="">
        <img className={style.icon} src={Instagram} alt="Icon Instagram" />
      </NavLink>
      <NavLink className={style.link} to="">
        <img className={style.icon} src={Twitter} alt="Icon Twitter" />
      </NavLink>
      </div>
    </div>
  );
};

export default Social_Network;
