import React, { useEffect, useState } from 'react';
import style from './Horaire.module.scss';
import firebase from 'firebase/compat/app';
import Ouvert from "../../../../../assets/images/Icons/Ouvert.png";
import Fermé from "../../../../../assets/images/Icons/Closed2.png";
import Modifier from "../../../../../assets/images/Icons/Modifier.png";
import Valider from "../../../../../assets/images/Icons/Valider.png";
import Annuler from "../../../../../assets/images/Icons/Annuler.png";

const Horaire = () => {
  const [horaires, setHoraires] = useState([]);
  const [modif, setModif] = useState(false);
  const [ouvertureInput, setOuvertureInput] = useState("");
  const [fermetureInput, setFermetureInput] = useState("");
  const [jourModifie, setJourModifie] = useState(null);

  useEffect(() => {
    const db = firebase.firestore();
    const horairesCollection = db.collection('Horaire');

    horairesCollection.get().then((querySnapshot) => {
      const horairesData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        horairesData.push({
          id: doc.id,
          ouverture: data.ouverture,
          fermeture: data.fermeture,
          fermé: data.fermé,
        });
      });
      const sortedHoraires = horairesData.sort((a, b) => {
        const daysOrder = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche', 'férié'];
        return daysOrder.indexOf(a.id) - daysOrder.indexOf(b.id);
      });

      setHoraires(sortedHoraires);
    });
  }, []);

  // Fonction pour mettre à jour le statut "fermé" dans Firestore
  const toggleFermeStatus = (horaireId, currentFermeStatus) => {
    const db = firebase.firestore();
    const horaireRef = db.collection('Horaire').doc(horaireId);

    // Inversez le statut "fermé" (true devient false et vice versa)
    const newFermeStatus = !currentFermeStatus;

    // Mettez à jour le document Firestore avec le nouveau statut "fermé"
    horaireRef.update({ fermé: newFermeStatus }).then(() => {
      // Mettez à jour l'état local (React state) si nécessaire
      setHoraires((prevHoraires) =>
        prevHoraires.map((horaire) =>
          horaire.id === horaireId ? { ...horaire, fermé: newFermeStatus } : horaire
        )
      );
    });
  };

  const handleModif = (horaire) => {
    setModif(true);
    setJourModifie(horaire.id); // Enregistrez le jour actuellement modifié
    setOuvertureInput(horaire.ouverture); // Initialisez l'input ouverture avec la valeur actuelle
    setFermetureInput(horaire.fermeture); // Initialisez l'input fermeture avec la valeur actuelle
  };

  const handleValider = (horaireId) => {
    const db = firebase.firestore();
    const horaireRef = db.collection('Horaire').doc(horaireId);

    // Mettez à jour le document Firestore avec les nouvelles valeurs d'ouverture et de fermeture
    horaireRef.update({ ouverture: ouvertureInput, fermeture: fermetureInput }).then(() => {
      // Mettez à jour l'état local (React state) si nécessaire
      setHoraires((prevHoraires) =>
        prevHoraires.map((horaire) =>
          horaire.id === horaireId ? { ...horaire, ouverture: ouvertureInput, fermeture: fermetureInput } : horaire
        )
      );
      setModif(false);
      setJourModifie(null); // Réinitialisez le jour modifié
    });
  };

  const handleAnnuler = () => {
    setModif(false);
    setJourModifie(null); // Réinitialisez le jour modifié
  };

  return (
    <div className={style.container}>
      {horaires.map((horaire) => (
        <div className={style.horaire} key={horaire.id}>
          <div className={style.top}>
            <h3 className={modif && jourModifie === horaire.id ? style.actif : null}>{horaire.id}</h3>
          </div>
          <div className={style.body}>
            {modif === true && jourModifie === horaire.id ? (
              <div className={style.inputs}>
                <p>Ouverture</p>
                <input
                  type="time"
                  value={ouvertureInput}
                  onChange={(e) => setOuvertureInput(e.target.value)}
                  style={{ backgroundColor: 'var(--secondary)' }} // Ajoutez le style du fond ici
                />
                <p>Fermeture</p>
                <input
                  type="time"
                  value={fermetureInput}
                  onChange={(e) => setFermetureInput(e.target.value)}
                  style={{ backgroundColor: 'var(--secondary)' }} // Ajoutez le style du fond ici
                />
              </div>
            ) : (
              horaire.fermé === true ? (
                <p>Fermé</p>
              ) : (
                <p>
                  {horaire.ouverture} - {horaire.fermeture}
                </p>
              )
            )}
          </div>
          <div className={style.icons}>
            {modif === true && jourModifie === horaire.id ? (
              <>
                <img src={Valider} alt="Valider" onClick={() => handleValider(horaire.id)} />
                <img src={Annuler} alt="Annuler" onClick={handleAnnuler} />
              </>
            ) : (
              <>
                <img
                  src={Modifier}
                  alt="Modifier"
                  onClick={() => handleModif(horaire)}
                />
                {horaire.fermé === true ? (
                  <img
                    src={Fermé}
                    alt="Ouvert"
                    onClick={() => toggleFermeStatus(horaire.id, horaire.fermé)}
                  />
                ) : (
                  <img
                    src={Ouvert}
                    alt="Fermé"
                    onClick={() => toggleFermeStatus(horaire.id, horaire.fermé)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Horaire;
