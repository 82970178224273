import React from "react";
import style from "./Categorie.module.scss";

const Categorie = ({ nom, actif, handle }) => {
  return (
    <div
      className={`${style.container} ${actif === nom ? style.actif : ""}`}
      onClick={() => handle(nom)}
    >
      <h3>{nom}</h3>
    </div>
  );
};

export default Categorie;
