// Import des composants et des bibliothèques nécessaires
import Button from "../../components/Button/Button"; // Import du composant de bouton
import DoubleTitre from "../../components/DoubleTitre/DoubleTitre"; // Import du composant DoubleTitre
import Empty from "../../components/Empty/Empty"; // Import du composant Empty
import Horaire from "../../components/Horaire/Horaire"; // Import du composant Horaire
import Wallpaper from "../../components/Wallpaper/Wallpaper"; // Import du composant Wallpaper
import style from "./Contact.module.scss"; // Import du fichier de styles
import Chef from "../../assets/images/Divers/Chef.jpg"; // Import de l'image du chef
import { useState } from "react"; // Import de useState depuis React
import * as Yup from "yup"; // Import de Yup pour la validation de formulaire
import { NavLink } from "react-router-dom";

// Définition du composant Contact
const Contact = () => {
  // Utilisation de useState pour gérer l'état du formulaire
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    numero: "",
    mail: "",
    message: "",
    consent: false,
  });

  // Utilisation de useState pour gérer les erreurs du formulaire
  const [formErrors, setFormErrors] = useState({
    nom: "",
    prenom: "",
    numero: "",
    mail: "",
    message: "",
    consent: "",
  });

  // Fonction de gestion des changements dans les champs du formulaire
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      if (name === "numero") {
        const phoneNumberWithoutSpaces = value.replace(/\s/g, "");
        setFormData({
          ...formData,
          [name]: phoneNumberWithoutSpaces,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  // Schéma de validation du formulaire avec Yup
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("Le nom est requis"),
    prenom: Yup.string().required("Le prénom est requis"),
    numero: Yup.string()
      .matches(/^\d{10}$/, "Le numéro de téléphone doit contenir 10 chiffres")
      .required("Le numéro de téléphone est requis"),
    mail: Yup.string()
      .email("L'adresse e-mail n'est pas valide")
      .required("L'adresse e-mail est requise"),
    message: Yup.string().required("Le message est requis"),
    consent: Yup.boolean()
      .oneOf([true], "Vous devez accepter la politique de confidentialité")
      .required("Vous devez accepter la politique de confidentialité"), 
  });

  // Fonction de soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validation du formulaire avec Yup
      await validationSchema.validate(formData, { abortEarly: false });

      // Envoi des données du formulaire vers le backend
      const response = await fetch("http://localhost:8000/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        alert("Merci beaucoup pour votre avis");
        // Réinitialisation des données du formulaire et des erreurs après une soumission réussie
        setFormData({
          nom: "",
          prenom: "",
          numero: "",
          mail: "",
          message: "",
          consent: false,
        });
        setFormErrors({
          nom: "",
          prenom: "",
          numero: "",
          mail: "",
          message: "",
          consent: "",
        });
      } else {
        console.error("Erreur lors de la demande POST vers le backend.");
        alert("L'avis n'a pas été envoyé , veuillez réessayer");
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const errorMessages = {};
        error.inner.forEach((e) => {
          errorMessages[e.path] = e.message;
        });
        setFormErrors(errorMessages); // Définition des erreurs du formulaire avec les messages d'erreur
      } else {
        console.error("Erreur lors de l'envoi de la demande POST :", error);
      }
    }
  };

  // Numéro de téléphone
  const phoneNumber = "0361125056";

  // Fonction pour gérer l'ouverture de l'application téléphone
  const handleOpenPhoneApp = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  // Fonction pour gérer le clic sur le bouton "Réserver"
  const handleReserver = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // Si l'utilisateur accède au site depuis un téléphone
      handleOpenPhoneApp();
    } else {
      // Si l'utilisateur accède au site depuis un ordinateur
      const dummyElement = document.createElement("textarea");
      dummyElement.value = phoneNumber;
      document.body.appendChild(dummyElement);
      dummyElement.select();
      document.execCommand("copy");
      document.body.removeChild(dummyElement);
      alert("Numéro de téléphone copié");
    }
  };

  // Rendu du composant Contact
    return(
<div className={style.container}>
<Wallpaper
picture="Contact"
text = "Accès et Contact"
/>
<div className={style.body}>
    <div className={style.informations}>
        <DoubleTitre h3="Informations" h2="Au Taquet" />
        <div className={style.informationsContents}>
            <div className={style.infos}>
                <h5>11 Pl. du Général de Gaulle, 62136 Richebourg</h5>
                <h5>03 61 12 50 56</h5>
                <Button text="Réserver" onClick={handleReserver} />
                
            </div>
            <div className={style.map}><iframe   width="100%" height="400" title="Map" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=1000&amp;height=600&amp;hl=fr&amp;q=11%20Pl.%20du%20G%C3%A9n%C3%A9ral%20de%20Gaulle,%2062136%20Richebourg+(Le%20Jardin%20Gourmand)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Au Taquet</a></iframe></div>
            <div className={style.map2}><iframe   width="100%" height="200" title="Map" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=1000&amp;height=600&amp;hl=fr&amp;q=11%20Pl.%20du%20G%C3%A9n%C3%A9ral%20de%20Gaulle,%2062136%20Richebourg+(Le%20Jardin%20Gourmand)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Au Taquet</a></iframe></div>
        </div>
        <Horaire />
    </div>
    
</div>
<div className={style.greenBackground}>
    <div className={style.body}>
        <div className={style.contactTitle}>
            <h2>Nous contacter</h2>
            <Empty />
            </div>
        <div className={style.contact}>
            <img src={Chef} alt="notre chef cuisinier" className={style.contactPicture} />
            <div className={style.contactContents}>
                <div className={style.contactText}>
                   <h5>Vous désirez nous contacter ?</h5>
                   <h5>Remplissez le formulaire ci-dessous !</h5>
                </div>
                <form name="Mail" onSubmit={handleSubmit}>
        <div className={style.form}>
          <div className={style.input}>
            <div className={style.withError}>
            <input
              type="text"
              name="nom"
              placeholder="Nom"
              value={formData.nom}
              onChange={handleInputChange}
            />
            {formErrors.nom && <p className={style.error}>{formErrors.nom}</p>}
            </div>
            <div className={style.withError}>
            <input
              type="text"
              name="prenom"
              placeholder="Prénom"
              value={formData.prenom}
              onChange={handleInputChange}
            />
            {formErrors.prenom && <p className={style.error}>{formErrors.prenom}</p>}
            </div>
            <div className={style.withError}>
            <input
              type="text"
              name="numero"
              placeholder="Numéro de téléphone"
              value={formData.numero}
              onChange={handleInputChange}
            />
            {formErrors.numero && <p className={style.error}>{formErrors.numero}</p>}
            </div>
            <div className={style.withError}>
            <input
              type="email"
              name="mail"
              placeholder="Adresse e-mail"
              value={formData.mail}
              onChange={handleInputChange}
            />
            {formErrors.mail && <p className={style.error}>{formErrors.mail}</p>}
            </div>
          </div>
          <div className={style.withError2}>
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
          {formErrors.message && <p className={style.error}>{formErrors.message}</p>}
          </div>
        </div>
       
        <div className={style.checkbox}>
          <input type="checkbox" name="consent" checked={formData.consent} onChange={handleInputChange} />
         <p>Je consens à ce que mes données personnelles soient traitées conformément à <NavLink style={{color: "lightblue", cursor: "pointer", textDecoration: "underline"}} to="/Politique-CGU-Cookies-Mention" state={{contenu: "Politique"}}>la politique de confidentialité du site.</NavLink></p>
        </div>
        {formErrors.consent && <p className={style.error}>{formErrors.consent}</p>}
      
        <Button text="Envoyer" />
      </form>
            </div>
        </div>
        </div>
    </div>
</div>
    )
}
export default Contact