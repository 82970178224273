import Pictures from "./components/Pictures/Pictures"
import Wallpaper from "../../components/Wallpaper/Wallpaper"
import style from "./Photos.module.scss"
import Exterieur from "../../assets/images/Divers/Exterieur.jpg"
import Interieur from "../../assets/images/Divers/Decoration_Home.webp"
import Equipe from "../../assets/images/Divers/Equipe.jpg"
import Plats from "../../assets/images/Divers/Plats.jpg"
import React from 'react';


const Photos = () => {



const handleLightOpen = () => {
  document.body.classList.add("no-scroll");
}

const handleLightClose = () => {
  document.body.classList.remove("no-scroll");
}



  return ( 
    
    <div className={style.container}>
      <Wallpaper picture="Photos" text="Photos" />
      <div className={style.body}>
        <h2>Photothèque</h2>
        <Pictures picture={Interieur} text="Le restaurant" photos="Interieur" scroll={handleLightClose} noscroll={handleLightOpen} />
        <Pictures picture={Plats} text="Nos plats" photos="Plats" scroll={handleLightClose} noscroll={handleLightOpen} />
        <Pictures picture={Equipe} text="Notre équipe" photos="Equipe" scroll={handleLightClose} noscroll={handleLightOpen} />
        <Pictures picture={Exterieur} text="L'extérieur du restaurant" photos="Exterieur" scroll={handleLightClose} noscroll={handleLightOpen} />
      </div>
    </div>
    
  );
}

export default Photos;
