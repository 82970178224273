import style from "./Footer.module.scss";
import Facebook from "../../assets/images/Icons/Facebook.png";
import Twitter from "../../assets/images/Icons/Twitter.png";
import Instagram from "../../assets/images/Icons/Instagram.png";
import Empty from "../Empty/Empty";
import Button from "../Button/Button";
import { NavLink } from "react-router-dom";

const Footer = () => {
     // Numéro de téléphone
     const phoneNumber = "0361125056";

     // Fonction pour gérer l'ouverture de l'application téléphone
     const handleOpenPhoneApp = () => {
         window.location.href = `tel:${phoneNumber}`;
     };
 
     // Fonction pour gérer le clic sur le bouton "Réserver"
     const handleReserver = () => {
         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
             // Si l'utilisateur accède au site depuis un téléphone
             handleOpenPhoneApp();
         } else {
             // Si l'utilisateur accède au site depuis un ordinateur
             const dummyElement = document.createElement("textarea");
             dummyElement.value = phoneNumber;
             document.body.appendChild(dummyElement);
             dummyElement.select();
             document.execCommand("copy");
             document.body.removeChild(dummyElement);
             alert("Numéro de téléphone copié");
         }
     };
 
    return (
        <div className={style.container}>
            <div className={style.wallpaper}>
                <div className={style.wallpaperContent}>
                    <div className={style.adresse}>
                        <div className={style.title}>
                    <h4>Adresse</h4>
                    <Empty />
                    </div>
                    <h5>11 Pl. du Général de Gaulle, 62136 Richebourg</h5>
                    <h5>03 61 12 50 56</h5>
                    </div>
                    <div className={style.contact}>
                    <div className={style.title}>
                    <h4>Nous contacter</h4>
                    <Empty />
                    </div>
                    <div className={style.logo}>
                    <img src={Facebook} alt="Facebook" />
                    <div className={style.emptyVertical}></div>
                    <img src={Instagram} alt="Instagram" />
                    <div className={style.emptyVertical}></div>
                    <img src={Twitter} alt="Twitter" />
                
                    </div>    
                    <div>
                    <Button text="Page Contact" link="/contact" />
                    </div>
                    </div>
                    <div className={style.réservation}>
                    <div className={style.title}>
                        <h4>Réservation</h4>
                        <Empty />
                    </div>
                    <Button text="Réserver" onClick={handleReserver} />
                    </div>
                </div>
            </div>
            <div className={style.blackbar}>
                <div className={style.bar}>
                <div className={style.leftbar}>
            <h5>© 2023 Au Taquet</h5>
                </div>
                <div className={style.rightbar}>
           <NavLink to="/Politique-CGU-Cookies-Mention" state={{contenu: "Mention"}}><h5>Mention légales</h5></NavLink>
            <div className={style.emptyVertical}></div>
            <NavLink to="/Politique-CGU-Cookies-Mention" state={{contenu: "CGU"}}><h5>CGU</h5></NavLink>
            <div className={style.emptyVertical}></div>
            <NavLink to="/Politique-CGU-Cookies-Mention" state={{contenu: "Politique"}}><h5>Politique de Confientialité</h5></NavLink>
           
                </div>
            </div>
            </div>
        </div>
    );
};

export default Footer;
