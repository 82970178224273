import style from "./DoubleTitre.module.scss"

const DoubleTitre = ({h3 , h2}) => {
    return(
<div className={style.titleAccueil}>
        <h3>{h3}</h3>
        <h2>{h2}</h2>
        </div>
    )
}

export default DoubleTitre