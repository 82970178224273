import { NavLink } from "react-router-dom";
import style from "./Button.module.scss";

const Button = ({ text, link, prop, onClick }) => {
  return link ? (
    <NavLink className={style.button} to={link} state={{ contenu: prop }}>
      <button onClick={onClick}>{text}</button>
    </NavLink>
  ) : (
    <button className={style.button2} onClick={onClick}>{text}</button>
  );
};

export default Button;
