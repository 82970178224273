import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import style from './Carte.module.scss';

const Carte = ({ categorie, alcool }) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = firebase.firestore();
        const menuRef = db.collection('Menu').doc(categorie);
        const doc = await menuRef.get();
  
        if (doc.exists) {
          const data = doc.data();
          // Convertissez les données Firestore en tableau
          const menuArray = Object.values(data);
  
          // Ajoutez l'ID du document à chaque élément du tableau
          const menuItemsWithId = menuArray.map((plat, index) => {
            return {
              id: Object.keys(data)[index], // L'ID est extrait ici
              ...plat,
            };
          });
  
          // Triez les plats par un critère, par exemple, par leur nom (id)
          menuItemsWithId.sort((a, b) => a.id.localeCompare(b.id));
  
          setMenuItems(menuItemsWithId);

          
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
  
    fetchData();
  }, [categorie]);
  
  

  return (
    <div className={style.container}>
      <div className={style.title}>
        <div className={style.emptyVertical}></div>
        <h2>
          {categorie} {categorie === "Cocktails" && alcool && "avec alcool"}{" "}
          {categorie === "Cocktails" && !alcool && "sans alcool"}
        </h2>
      </div>
      {menuItems.length === 0 ? ( 
        <h4>Aucun/e {categorie} pour le moment</h4>
      ) : (
       
        menuItems
          .filter((plat) => (categorie === "Cocktails" ? plat.Alcool === alcool : true))
          .map((plats) => (
            <div className={style.plat} key={plats.id}>
              <div className={style.titrePlat}>
                <h4>
                  {plats.id}{" "}
                  {categorie === "Cocktail de la semaine" && plats.Alcool && "(avec alcool)"}{" "}
                  {categorie === "Cocktail de la semaine" && !plats.Alcool && "(sans alcool)"}
                </h4>
                <p></p>
                <p>{plats.prix}€</p>
              </div>
              <div className={style.ingredients}>
                <p>{plats.ingrédients.join(", ")}</p>
              </div>
            </div>
          ))
      )}
    </div>
  );
  
};

export default Carte;
