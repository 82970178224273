import { useState, useEffect } from "react";
import style from "./Connexion.module.scss";
import firebase from 'firebase/compat/app';
import { Hourglass } from 'react-loader-spinner'
import Button from "../../../components/Button/Button"

const Connexion = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // État pour gérer l'affichage du loader

  useEffect(() => {
    // Vérifier si l'utilisateur est déjà connecté
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // L'utilisateur est déjà connecté, redirigez-le vers "/admin"
        window.location.href = "/admin";
      }
    });

    // Arrêtez l'écoute de l'état de l'authentification lorsque le composant est démonté
    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true); // Active le loader pendant la tentative de connexion

    // Utilise setTimeout pour ajouter un délai de 3 secondes
    setTimeout(async () => {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        window.location.href = "/admin";
      } catch (error) {
        setError("Adresse mail ou Mot de passe erroné");
      } finally {
        setIsLoading(false); // Désactive le loader après le délai
      }
    }, 2000);
  };

  return (
    <div className={style.login}>
    <div className={style.container}>
      <h1 className={style.title}>Connexion à la page admin</h1>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.inputGroup}>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={style.input}
            placeholder="Email"
            required
          />
        </div>
        <div className={style.inputGroup}>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={style.input}
            placeholder="Mot de passe"
            required
          />
        </div>
        <Button text="Se connecter"/>
          
      </form>
      {isLoading && <Hourglass
  visible={true}
  height="30"
  width="30"
  ariaLabel="hourglass-loading"
  wrapperStyle={{}}
  wrapperClass=""
  colors={["#707070", '#FFFFFF']}
/>} {/* Affiche le loader pendant l'attente */}
      {error && <p className={style.error}>{error}</p>}
    </div>
    </div>
  );
};

export default Connexion;
