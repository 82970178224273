import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Empty from "../../../components/Empty/Empty";
import style from "./Admin.module.scss";
import Categorie from "./components/Categorie/Categorie";
import Pannel from "./components/Pannel/Pannel";
import firebase from 'firebase/compat/app';
import Tableau from "./components/Tableau/Tableau";
import Horaire from "./components/Horaire/Horaire";
import iconBurger from "../../../assets/images/Icons/Burger.png"
import Burger from "../../../components/Burger/Burger";



const Admin = () => {
  const [actif1, setActif1] = useState("Accueil");
  const [actif2, setActif2] = useState("");
  const [burger , setBurger] = useState(false);
  const navigate = useNavigate();

  const handleActif1 = (nom) => {
    setActif1(nom);
    setActif2("")
    setBurger(false)
  };

  const handleActif2 = (nom) => {
    setActif2(nom);
  };

  // Fonction pour se déconnecter
  const handleDeco = () => {
   
    firebase.auth().signOut().then(() => {
     
      navigate("/connexion-admin");
    }).catch((error) => {
    
      console.error("Erreur lors de la déconnexion : ", error);
    });
  };

  useEffect(() => {
    
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
   
        navigate("/connexion-admin");
      }
    });
  return () => unsubscribe();
  }, [navigate]);

  const handleBurger = () => {
    setBurger(!burger)
  }

  return (
 
    <div className={style.container}>
      <img className={style.burger} src={iconBurger} alt="Burger" onClick={handleBurger} />
      <div className={style.leftBar}>
        <div className={style.titleLeftBar}>
          <h2>Menu Admin</h2>
          <Empty />
        </div>
        <div className={style.categorieLeftBar}>
          <Categorie nom="Accueil" actif={actif1} handle={handleActif1} />
          <Categorie nom="Modifier le menu" actif={actif1} handle={handleActif1} />
          <Categorie nom="Modifier les horaires" actif={actif1} handle={handleActif1} />
        </div>
      </div>
      <div className={style.rightBar}>
        {actif1 === "Accueil" && (
          <>
            <div className={style.titleRightBar}>
              <h2>Accueil</h2>
            </div>
            <div className={style.content}>
              <p className={style.welcome}>Bienvenue sur la page d'accueil du menu admin. Que voulez-vous faire ?</p>
              <div className={style.pannel}>
                <Pannel contenu="Modifier le menu" handle={handleActif1}  />
                <Pannel contenu="Modifier les horaires" handle={handleActif1} />
                <Pannel contenu="Deconnexion" handle={handleDeco} />
              </div>
              
            </div>
          </>
        )}
        {actif1 === "Modifier le menu" && (
          <>
            <div className={style.titleRightBar}>
              <h2>Modifier le menu</h2>
            </div>
            <div className={style.content}>
              <p className={style.welcome}>Bienvenue sur la page de modification du menu. Que voulez-vous modifier ?</p>
              <div className={style.pannel}>
                <Pannel contenu="Cocktails" handle={handleActif2} actif={actif2}  />
                <Pannel contenu="Entrées" handle={handleActif2} actif={actif2}  />
                <Pannel contenu="Plats" handle={handleActif2} actif={actif2} />
                <Pannel contenu="Desserts" handle={handleActif2} actif={actif2} />
               
              </div>
              {actif2 && <Tableau content={actif2} />}

        </div>
          </>
        )}
        {actif1 === "Modifier les horaires" && (
       <>
            <div className={style.titleRightBar}>
              <h2>Modifier le menu</h2>
            </div>
            <div className={style.content}>
            <p className={style.welcome}>Bienvenue sur la page de modification des horaires. Que voulez-vous modifier ?</p>
            <Horaire />
            </div>
          </>
        )}
      </div>
      {burger && <Burger handle={handleBurger} type="2" actif={actif1} handle2={handleActif1} />}
    </div>
    
  );
};

export default Admin;
