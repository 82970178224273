import React, { useEffect, useState } from 'react';
import Date from './components/Date';
import style from './Horaire.module.scss';
import Empty from '../Empty/Empty';
import firebase from 'firebase/compat/app';

const Horaire = () => {
  const [horaires, setHoraires] = useState([]);

  useEffect(() => {
    const db = firebase.firestore();
    const horairesCollection = db.collection('Horaire');

    horairesCollection.get().then((querySnapshot) => {
      const horairesData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        horairesData.push({
          jour: doc.id,
          ouverture: data.ouverture,
          fermeture: data.fermeture,
          fermé: data.fermé,
        });
      });

      
      const sortedHoraires = horairesData.sort((a, b) => {
        const daysOrder = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche', 'férié'];
        return daysOrder.indexOf(a.jour) - daysOrder.indexOf(b.jour);
      });

      setHoraires(sortedHoraires);
    });
  }, []);

  return (
    <div className={style.container}>
      <div className={style.title}>
        <h3>Horaire</h3>
        <Empty />
      </div>
      <div className={style.contents}>
        {horaires.map((horaire, index) => (
          <Date
            key={index}
            jour={horaire.jour}
            fermé={horaire.fermé}
            ouverture={horaire.ouverture}
            fermeture={horaire.fermeture}
          />
        ))}
      </div>
    </div>
  );
};

export default Horaire;
